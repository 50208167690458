import { render, staticRenderFns } from "./LayoutHero.vue?vue&type=template&id=5d5003d7"
import script from "./LayoutHero.vue?vue&type=script&lang=js"
export * from "./LayoutHero.vue?vue&type=script&lang=js"
import style0 from "./LayoutHero.vue?vue&type=style&index=0&id=5d5003d7&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports