<template>
  <!-- Footer -->
  <footer class="iv2-footer theme__footer-bg f-14">
    <div class="iv2-footer__container theme__footer-text">
      <div class="iv2-footer__logo">
        <router-link :to="'/' + query">
          <picture>
            <source
              type="image/webp"
              :srcset="
                information.logo_image_footer +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.logo_image_footer"
              class="iv2-footer__logo-img"
              :alt="organization.name + $store.state.publicLang.general.alt"
            />
          </picture>
        </router-link>
      </div>
      <div class="row">
        <div class="iv2-footer__item col-xl-6">
          <div class="d-none d-md-block">
            <p class="m-0 size-14">{{ organization.name }}</p>
            <div v-if="organization.tel_number !== null" class="d-inline-flex">
              <span class="mt-auto mr-2">{{
                $store.state.themeLang.general.tel
              }}</span>
              <p class="mb-1 size-28" style="line-height: 1">
                {{ organization.tel_number }}
              </p>
            </div>
            <p
              v-if="organization.opening_hours !== null"
              v-html="organization.opening_hours"
              class="size-12 mt-xl-0"
              style="line-height: 1.3"
            ></p>
            <div class="d-flex">
              <span v-if="organization.zip_code !== null" class="m-0 size-14">
                {{ "〒" + organization.zip_code }}
              </span>
              <span
                v-if="organization.prefecture_name !== null"
                class="size-14 m-0"
                >{{ organization.prefecture_name }}</span
              >
              <span
                v-if="organization.city_name !== null"
                class="size-14 m-0"
                >{{ organization.city_name }}</span
              >
              <span
                v-if="organization.town_name !== null"
                class="size-14 m-0"
                >{{ organization.town_name }}</span
              >
              <span
                v-if="organization.building_name !== null"
                class="size-14 m-0"
                >{{ organization.building_name }}</span
              >
            </div>
            <p
              v-if="organization.free_text !== null"
              v-html="organization.free_text"
              class="size-14 m-0"
            ></p>
          </div>
          <p class="size-16">{{ information.meta_description }}</p>
        </div>
        <div class="iv2-footer__table col-xl-6">
          <p v-html="table_ttl"></p>
          <table class="text-center">
            <thead>
              <tr>
                <th v-for="item in items" :key="item.id" class>
                  {{ item.title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{{ time_1.title }}</th>
                <td v-for="item in time_1.items" :key="item.id">
                  {{ item.status }}
                </td>
              </tr>
              <tr>
                <th>{{ time_2.title }}</th>
                <td v-for="item in time_2.items" :key="item.id">
                  {{ item.status }}
                </td>
              </tr>
            </tbody>
          </table>
          <p v-html="table_text" class="size-16"></p>
        </div>
      </div>
      <div class="iv2-footer__sns my-3 d-md-none d-flex">
        <span
          v-if="
            sns.is_linked_facebook == 0 &&
            sns.is_linked_instagram == 0 &&
            sns.is_linked_twitter == 0 &&
            sns.is_linked_line == 0
          "
        ></span>
        <span v-else class="size-12 mr-3 ml-auto" style="padding-top: 6px">{{
          $store.state.themeLang.general.sns
        }}</span>
        <ul class="p-0 mr-auto" style="display: flex; list-style: none">
          <li v-if="sns.is_linked_facebook == 1">
            <a
              :href="sns.facebook_url"
              class="iv2-footer__sns-link theme__footer-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-footer__sns-icon text-center fab fa-facebook-f"></i>
            </a>
          </li>
          <li v-if="sns.is_linked_instagram == 1">
            <a
              :href="sns.instagram_url"
              class="iv2-footer__sns-link theme__footer-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-footer__sns-icon text-center fab fa-instagram"></i>
            </a>
          </li>
          <li v-if="sns.is_linked_twitter == 1">
            <a
              :href="sns.twitter_url"
              class="iv2-footer__sns-link theme__footer-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-footer__sns-icon text-center fab fa-twitter"></i>
            </a>
          </li>
          <li v-if="sns.is_linked_line == 1">
            <a
              :href="sns.line_url"
              class="iv2-footer__sns-link theme__footer-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-footer__sns-icon text-center fab fa-line"></i>
            </a>
          </li>
          <!-- <li v-if="rss.is_linked_blog == 1">
						<a :href="rss.url" class="iv2-footer__sns-link theme__footer-link" target="_blank" rel="noopener">
							<i class="iv2-footer__sns-icon text-center fas fa-rss"></i>
						</a>
					</li> -->
        </ul>
      </div>
      <hr class="iv2-footer__hr theme__footer-hr d-none d-xl-block" />
      <div
        v-if="organization.copy_right !== null"
        class="iv2-footer__bottom row"
      >
        <div class="iv2-footer__copyright col-xl-6 text-center text-xl-left">
          <small>{{ organization.copy_right }}</small>
        </div>
        <div
          @click="scrollTop"
          class="iv2-footer__pagetop col-6 d-none d-xl-block text-right"
        >
          <small
            >{{ $store.state.themeLang.general.page_top
            }}<i class="fas fa-angle-double-up ml-2"></i
          ></small>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    sns: Object,
    organization: Object,
    information: Object,
    rss: Object,
  },

  data() {
    return {
      items: [
        {
          id: 1,
          title: "",
        },
        {
          id: 2,
          title: "月",
        },
        {
          id: 3,
          title: "火",
        },
        {
          id: 4,
          title: "水",
        },
        {
          id: 5,
          title: "木",
        },
        {
          id: 6,
          title: "金",
        },
        {
          id: 7,
          title: "土",
        },
        {
          id: 8,
          title: "日",
        },
      ],
      time_1: {
        title: "9:30〜12:30",
        items: [
          {
            id: 1,
            status: "●",
          },
          {
            id: 2,
            status: "／",
          },
          {
            id: 3,
            status: "●",
          },
          {
            id: 4,
            status: "●",
          },
          {
            id: 5,
            status: "●",
          },
          {
            id: 6,
            status: "●",
          },
          {
            id: 7,
            status: "●",
          },
        ],
      },
      time_2: {
        title: "15:30〜19:00",
        items: [
          {
            id: 1,
            status: "●",
          },
          {
            id: 2,
            status: "／",
          },
          {
            id: 3,
            status: "●",
          },
          {
            id: 4,
            status: "●",
          },
          {
            id: 5,
            status: "●",
          },
          {
            id: 6,
            status: "●",
          },
          {
            id: 7,
            status: "／",
          },
        ],
      },
      table_ttl:
        "診療時間<br><b>当院は予約優先診療です。来院前にお電話ください。</b>",
      table_text: "※祝祭日は午前のみ受付となります。",
      query: "",
    };
  },

  mounted() {
    if (this.$route.query.mode == "preview" && this.$route.query.api_token) {
      this.query =
        "?mode=preview&api_token=" +
        this.$route.query.api_token +
        "&locale=" +
        this.$route.query.locale;
    } else if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
  },

  methods: {
    scrollTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.iv2-footer__container {
  width: 90%;
  margin: auto;
  font-family: "Times New Roman", "Hiragino Mincho ProN", "Noto serif JP", serif;

  .iv2-footer__logo {
    margin: 20px 0;

    .iv2-footer__logo-img {
      max-width: 300px;
      max-height: 150px;
    }
  }

  .iv2-footer__item {
    text-align: left;
  }

  .iv2-footer__sns {
    .iv2-footer__sns-link {
      margin-right: 15px;
      font-size: 12px;

      .iv2-footer__sns-icon {
        width: 15px;
      }
    }
  }

  .iv2-footer__hr {
    padding: 0;
  }

  .iv2-footer__bottom {
    .iv2-footer__pagetop {
      cursor: pointer;
    }
  }

  .iv2-footer__table {
    table {
      border: 1px solid #000;
      border-collapse: separate;
      border-spacing: 0;
      border-radius: 10px;
      width: 100%;
      overflow: hidden;
    }

    table tbody th,
    table tbody td {
      border-bottom: 1px solid #000;
    }

    table thead th {
      background-color: #000;
      color: #fff;
    }

    table tbody td {
      border-left: 1px solid #000;
    }

    table tbody tr:last-child th,
    table tbody tr:last-child td {
      border-bottom: none;
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  .iv2-footer {
    padding: 50px 0;
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  .iv2-footer {
    padding: 30px 0;
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-footer {
    margin-bottom: 60px;
    padding-bottom: calc(env(safe-area-inset-bottom));
  }

  .iv2-footer__container {
    padding-bottom: 20px;
    .iv2-footer__logo {
      margin: 25px auto;
      text-align: center;
      .iv2-footer__logo-img {
        max-width: 80%;
      }
    }

    .iv2-footer__item {
      text-align: center;
    }

    .iv2-footer__nav {
      display: none;
    }
  }
}
</style>
