<template>
  <div v-if="active" class="iv2-customize__logo position-fixed">
    <img :src="image_src" :alt="alt_text" />
  </div>
</template>

<script>
import AssetsImage from "@/assets/unique/img/datenomachi_main-logo.png";
export default {
  data() {
    return {
      alt_text: "伊達の街どうぶつ病院のロゴ",
      image_src: AssetsImage,
      active: true,
    };
  },

  created() {
    if (this.$route.path !== "/") {
      this.active = false;
    }
  },

  watch: {
    $route: function (to, from) {
      if (to.path !== from.path && this.$route.path !== "/") {
        this.active = false;
      } else {
        this.active = true;
      }
    },
  },
};
</script>

<style lang="scss">
.iv2-customize__logo {
  right: 0;
  top: 150px;
  z-index: 5;
  width: 140px;
}

@media (max-width: 1199px) {
  .iv2-customize__logo {
    width: 100px;
  }
}

@media (max-width: 767px) {
  .iv2-customize__logo {
    width: 80px;
    top: 100px;
  }
}
</style>
